<!--
 * @Author: your name
 * @Date: 2020-12-18 13:47:12
 * @LastEditTime: 2021-02-22 16:34:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \tostumid:\project\web\sxvant\src\views\Home.vue
-->
<template>
  <div class="home">
    <router-view></router-view>
    <div class="tip">
      <p>欢迎来访广州华汇信息技术有限公司H5综合页面...</p>
    </div>
    <div class="btn">
      <p>
        域名备案 : <a href="https://beian.miit.gov.cn/">粤ICP备19071903号</a>
      </p>
    </div>
    <div class="night">
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
      <div class="shooting_star"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {};
  },
};
</script>

<style scoped>
.home {
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  height: 100vh;
  overflow: hidden;
  display: flex;
  font-family: "Anton", sans-serif;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.night {
  position: relative;
  width: 100%;
  height: 100%;
  transform: rotateZ(45deg);
  overflow: hidden;
}

.shooting_star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 2px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #699bff);
  -webkit-animation: tail 3000ms ease-in-out infinite,
    shooting 3000ms ease-in-out infinite;
  animation: tail 3000ms ease-in-out infinite,
    shooting 3000ms ease-in-out infinite;
}
.shooting_star::before {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 255, 0),
    #5f91ff,
    rgba(0, 0, 255, 0)
  );
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
  animation: shining 3000ms ease-in-out infinite;
}
.shooting_star::after {
  content: "";
  position: absolute;
  top: calc(50% - 1px);
  right: 0;
  height: 2px;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 255, 0),
    #5f91ff,
    rgba(0, 0, 255, 0)
  );
  transform: translateX(50%) rotateZ(45deg);
  border-radius: 100%;
  -webkit-animation: shining 3000ms ease-in-out infinite;
  animation: shining 3000ms ease-in-out infinite;
  transform: translateX(50%) rotateZ(-45deg);
}
.shooting_star:nth-child(1) {
  top: calc(50% - -27px);
  left: calc(50% - 236px);
  -webkit-animation-delay: 8752ms;
  animation-delay: 8752ms;
}
.shooting_star:nth-child(1)::before,
.shooting_star:nth-child(1)::after {
  -webkit-animation-delay: 8752ms;
  animation-delay: 8752ms;
}
.shooting_star:nth-child(2) {
  top: calc(50% - -110px);
  left: calc(50% - 125px);
  -webkit-animation-delay: 6721ms;
  animation-delay: 6721ms;
}
.shooting_star:nth-child(2)::before,
.shooting_star:nth-child(2)::after {
  -webkit-animation-delay: 6721ms;
  animation-delay: 6721ms;
}
.shooting_star:nth-child(3) {
  top: calc(50% - -10px);
  left: calc(50% - 283px);
  -webkit-animation-delay: 8915ms;
  animation-delay: 8915ms;
}
.shooting_star:nth-child(3)::before,
.shooting_star:nth-child(3)::after {
  -webkit-animation-delay: 8915ms;
  animation-delay: 8915ms;
}
.shooting_star:nth-child(4) {
  top: calc(50% - -150px);
  left: calc(50% - 63px);
  -webkit-animation-delay: 4859ms;
  animation-delay: 4859ms;
}
.shooting_star:nth-child(4)::before,
.shooting_star:nth-child(4)::after {
  -webkit-animation-delay: 4859ms;
  animation-delay: 4859ms;
}
.shooting_star:nth-child(5) {
  top: calc(50% - 21px);
  left: calc(50% - 154px);
  -webkit-animation-delay: 8735ms;
  animation-delay: 8735ms;
}
.shooting_star:nth-child(5)::before,
.shooting_star:nth-child(5)::after {
  -webkit-animation-delay: 8735ms;
  animation-delay: 8735ms;
}
.shooting_star:nth-child(6) {
  top: calc(50% - -106px);
  left: calc(50% - 130px);
  -webkit-animation-delay: 2949ms;
  animation-delay: 2949ms;
}
.shooting_star:nth-child(6)::before,
.shooting_star:nth-child(6)::after {
  -webkit-animation-delay: 2949ms;
  animation-delay: 2949ms;
}
.shooting_star:nth-child(7) {
  top: calc(50% - -90px);
  left: calc(50% - 95px);
  -webkit-animation-delay: 7816ms;
  animation-delay: 7816ms;
}
.shooting_star:nth-child(7)::before,
.shooting_star:nth-child(7)::after {
  -webkit-animation-delay: 7816ms;
  animation-delay: 7816ms;
}
.shooting_star:nth-child(8) {
  top: calc(50% - 50px);
  left: calc(50% - 117px);
  -webkit-animation-delay: 3768ms;
  animation-delay: 3768ms;
}
.shooting_star:nth-child(8)::before,
.shooting_star:nth-child(8)::after {
  -webkit-animation-delay: 3768ms;
  animation-delay: 3768ms;
}
.shooting_star:nth-child(9) {
  top: calc(50% - 190px);
  left: calc(50% - 158px);
  -webkit-animation-delay: 2620ms;
  animation-delay: 2620ms;
}
.shooting_star:nth-child(9)::before,
.shooting_star:nth-child(9)::after {
  -webkit-animation-delay: 2620ms;
  animation-delay: 2620ms;
}
.shooting_star:nth-child(10) {
  top: calc(50% - 6px);
  left: calc(50% - 96px);
  -webkit-animation-delay: 1842ms;
  animation-delay: 1842ms;
}
.shooting_star:nth-child(10)::before,
.shooting_star:nth-child(10)::after {
  -webkit-animation-delay: 1842ms;
  animation-delay: 1842ms;
}
.shooting_star:nth-child(11) {
  top: calc(50% - 129px);
  left: calc(50% - 133px);
  -webkit-animation-delay: 41ms;
  animation-delay: 41ms;
}
.shooting_star:nth-child(11)::before,
.shooting_star:nth-child(11)::after {
  -webkit-animation-delay: 41ms;
  animation-delay: 41ms;
}
.shooting_star:nth-child(12) {
  top: calc(50% - 22px);
  left: calc(50% - 275px);
  -webkit-animation-delay: 4525ms;
  animation-delay: 4525ms;
}
.shooting_star:nth-child(12)::before,
.shooting_star:nth-child(12)::after {
  -webkit-animation-delay: 4525ms;
  animation-delay: 4525ms;
}
.shooting_star:nth-child(13) {
  top: calc(50% - 143px);
  left: calc(50% - 219px);
  -webkit-animation-delay: 3600ms;
  animation-delay: 3600ms;
}
.shooting_star:nth-child(13)::before,
.shooting_star:nth-child(13)::after {
  -webkit-animation-delay: 3600ms;
  animation-delay: 3600ms;
}
.shooting_star:nth-child(14) {
  top: calc(50% - -156px);
  left: calc(50% - 142px);
  -webkit-animation-delay: 6309ms;
  animation-delay: 6309ms;
}
.shooting_star:nth-child(14)::before,
.shooting_star:nth-child(14)::after {
  -webkit-animation-delay: 6309ms;
  animation-delay: 6309ms;
}
.shooting_star:nth-child(15) {
  top: calc(50% - 100px);
  left: calc(50% - 145px);
  -webkit-animation-delay: 7858ms;
  animation-delay: 7858ms;
}
.shooting_star:nth-child(15)::before,
.shooting_star:nth-child(15)::after {
  -webkit-animation-delay: 7858ms;
  animation-delay: 7858ms;
}
.shooting_star:nth-child(16) {
  top: calc(50% - 137px);
  left: calc(50% - 40px);
  -webkit-animation-delay: 2250ms;
  animation-delay: 2250ms;
}
.shooting_star:nth-child(16)::before,
.shooting_star:nth-child(16)::after {
  -webkit-animation-delay: 2250ms;
  animation-delay: 2250ms;
}
.shooting_star:nth-child(17) {
  top: calc(50% - 75px);
  left: calc(50% - 107px);
  -webkit-animation-delay: 1351ms;
  animation-delay: 1351ms;
}
.shooting_star:nth-child(17)::before,
.shooting_star:nth-child(17)::after {
  -webkit-animation-delay: 1351ms;
  animation-delay: 1351ms;
}
.shooting_star:nth-child(18) {
  top: calc(50% - -108px);
  left: calc(50% - 42px);
  -webkit-animation-delay: 482ms;
  animation-delay: 482ms;
}
.shooting_star:nth-child(18)::before,
.shooting_star:nth-child(18)::after {
  -webkit-animation-delay: 482ms;
  animation-delay: 482ms;
}
.shooting_star:nth-child(19) {
  top: calc(50% - 156px);
  left: calc(50% - 159px);
  -webkit-animation-delay: 2341ms;
  animation-delay: 2341ms;
}
.shooting_star:nth-child(19)::before,
.shooting_star:nth-child(19)::after {
  -webkit-animation-delay: 2341ms;
  animation-delay: 2341ms;
}
.shooting_star:nth-child(20) {
  top: calc(50% - 152px);
  left: calc(50% - 120px);
  -webkit-animation-delay: 4565ms;
  animation-delay: 4565ms;
}
.shooting_star:nth-child(20)::before,
.shooting_star:nth-child(20)::after {
  -webkit-animation-delay: 4565ms;
  animation-delay: 4565ms;
}

@-webkit-keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}

@keyframes tail {
  0% {
    width: 0;
  }
  30% {
    width: 100px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@keyframes shining {
  0% {
    width: 0;
  }
  50% {
    width: 30px;
  }
  100% {
    width: 0;
  }
}
@-webkit-keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@keyframes shooting {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300px);
  }
}
@-webkit-keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}
@keyframes sky {
  0% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(405deg);
  }
}

.tip {
  position: absolute;
  top: 6%;
  left: 50%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  transform: translateX(-50%);
  width: 100%;
}
.tip a,
.btn a {
  color: #fff;
}
.btn {
  position: absolute;
  bottom: 6%;
  left: 50%;
  text-align: center;
  color: #fff;
  font-size: 14px;
  line-height: 30px;
  transform: translateX(-50%);
  width: 100%;
}
</style>
